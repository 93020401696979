import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { privateCallSelector } from "../../features/privateCall/privateCallSlice";
import { getInitials } from "../../utils/initials";
import { PhoneIcon, PhoneXMarkIcon } from "@heroicons/react/24/outline"; // Updated icons
import { PrivateCallModalProps } from "../../interfaces/componentsInterface/privateCallModal";
import { Howl, Howler } from "howler";
import { mediaSelector } from "../../features/media/mediaSlice";
import { generateThumbnailsUrl } from "../../utils/generateImageURL";
import { environmentSelector } from "../../features/environment/environmentSlice";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { peerVolumeSelector } from "../../features/peerVolume/peerVolumeSlice";
import { getVolumeClasses } from "../../utils/audio";

// Setup the new Howl.
const sound = new Howl({
  src: [`${process.env.REACT_APP_CDN_BASE_URL}/tones/gotitem.mp3`],
  loop: true,
});

export default function PrivateCallModal({
  makePrivateCall,
  callRejected,
  callAccepted,
}: PrivateCallModalProps): ReturnType<React.FC> {
  const [imageError, setImageError] = useState(false);
  const { callModalOption, isPrivateCallOn, openentCallerUserId } =
    useAppSelector(privateCallSelector);
  const media = useAppSelector(mediaSelector);
  const user = useAppSelector(userDetailsSelector);
  const peerVolume = useAppSelector(peerVolumeSelector);
  const environments = useAppSelector(environmentSelector);
  const [playbackRate] = useState(true);
  const spacesConfig =
    environments.environments &&
    environments.environments.REACT_APP_SPACES_CONFIG
      ? environments.environments.REACT_APP_SPACES_CONFIG.data[0]
      : "";

  let timeout = useRef() as any;
  const [position, setPosition] = useState<{ x: number; y: number } | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const dragStartPos = useRef({ x: 0, y: 0 });
  const modalRef = useRef<HTMLDivElement>(null);



const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  e.preventDefault();
  setIsDragging(true);

  dragStartPos.current = {
    x: e.clientX - (position?.x || 0),
    y: e.clientY - (position?.y || 0),
  };

  document.body.style.userSelect = "none";
};

const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
  e.preventDefault();
  setIsDragging(true);

  const touch = e.touches[0];

  dragStartPos.current = {
    x: touch.clientX - (position?.x || 0),
    y: touch.clientY - (position?.y || 0),
  };

  document.body.style.userSelect = "none";
};

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging) {
      let newX = e.clientX - dragStartPos.current.x;
      let newY = e.clientY - dragStartPos.current.y;
  
      const modal = modalRef.current;
      if (modal) {
        const { innerWidth, innerHeight } = window;
        const modalRect = modal.getBoundingClientRect();
  
        newX = Math.max(0, Math.min(newX, innerWidth - modalRect.width));
        newY = Math.max(0, Math.min(newY, innerHeight - modalRect.height));
  
        setPosition({ x: newX, y: newY });
      }
    }
  };

  useLayoutEffect(() => {
    if (modalRef.current) {
      const modal = modalRef.current;
      const { innerWidth, innerHeight } = window;
      const modalRect = modal.getBoundingClientRect();
      const rightMargin = 16; // pixels
      const topMargin = innerHeight * 0.08; // 8% from top
  
      const initialX = innerWidth - modalRect.width - rightMargin;
      const initialY = topMargin;
  
      setPosition({ x: initialX, y: initialY });
    }
  }, []);
  

  const handleTouchMove = (e: TouchEvent) => {
    if (isDragging) {
      const touch = e.touches[0];
      let newX = touch.clientX - dragStartPos.current.x;
      let newY = touch.clientY - dragStartPos.current.y;
  
      const modal = modalRef.current;
      if (modal) {
        const { innerWidth, innerHeight } = window;
        const modalRect = modal.getBoundingClientRect();
  
        newX = Math.max(0, Math.min(newX, innerWidth - modalRect.width));
        newY = Math.max(0, Math.min(newY, innerHeight - modalRect.height));
  
        setPosition({ x: newX, y: newY });
      }
    }
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
      document.body.style.userSelect = "auto";
    }
  };

  const handleTouchEnd = () => {
    if (isDragging) {
      setIsDragging(false);
      // Re-enable text selection
      document.body.style.userSelect = "auto";
    }
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
      window.addEventListener("touchmove", handleTouchMove, { passive: false });
      window.addEventListener("touchend", handleTouchEnd);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    }
  
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  useEffect(() => {
    if (callModalOption.callAcceptedButton) {
      timeout.current = setTimeout(() => {
        handleRing();
        callRejected(callModalOption.idForMakingCall, "CALL_DID_NOT_PICKUP");
      }, 25000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callModalOption.callAcceptedButton]);

  useEffect(() => {
    if (timeout.current) {
      return () => {
        clearTimeout(timeout.current);
      };
    }
  }, []);

  const callCut = () => {
    handleRing();
    if (isPrivateCallOn) {
      makePrivateCall(openentCallerUserId);
    } else if (callModalOption.peerRemoteUserId !== "") {
      callRejected(callModalOption.peerRemoteUserId, "CALL_CUT_BY_CALLER");
    } else {
      // clearTimeout(timeout.current);
      callRejected(callModalOption.idForMakingCall as string, "CALL_REJECTED");
    }
  };

  useEffect(() => {
    if (playbackRate && callModalOption.callAcceptedButton) {
      sound.play();
      Howler.volume(1);
    } else {
      sound.stop();
    }
    return () => {
      handleRing();
    };
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }, [playbackRate, callModalOption.callAcceptedButton]);
  const handleRing = () => {
    sound.stop();
    Howler.volume(0);
  };

  return (
    <>
      <div
        ref={modalRef}
        className="xl:w-[23%] lg:w-[25%] lg:h-[10%] xl:h-[8%] z-10 shadow-2xl bg-white fixed  rounded-2xl  flex justify-between items-center p-2"
        style={{
          top: position ? `${position.y}px` : "8%", 
          left: position ? `${position.x}px` : undefined,
          right: position ? undefined : "16px",
          cursor: isDragging ? "grabbing" : "grab",
          // Optional: Add transition for smoother dragging
          // transition: isDragging ? "none" : "top 0.2s, left 0.2s",
        }}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        {!isPrivateCallOn ? (
          <>
            <div className=" flex justify-start items-center gap-2">
              {imageError ? (
                <img
                  className={`inline-block h-9 w-9 rounded-full ring-2 ring-white ${
                    peerVolume &&
                    getVolumeClasses(
                      peerVolume[
                        callModalOption.peerRemoteUserId ||
                          callModalOption.idForMakingCall
                      ]
                    )
                  }`}
                  src={
                    media &&
                    media?.remotePeers[
                      callModalOption.peerRemoteUserId ||
                        callModalOption.idForMakingCall
                    ]?.imageUrl
                  }
                  alt="User"
                />
              ) : media &&
                media?.remotePeers[
                  callModalOption.peerRemoteUserId ||
                    callModalOption.idForMakingCall
                ]?.imageUrl &&
                !imageError ? (
                <img
                  className={`inline-block h-9 w-9 rounded-full ring-2 ring-white  ${
                    peerVolume &&
                    getVolumeClasses(
                      peerVolume[
                        callModalOption.peerRemoteUserId ||
                          callModalOption.idForMakingCall
                      ]
                    )
                  }`}
                  src={generateThumbnailsUrl(
                    media &&
                      media?.remotePeers[
                        callModalOption.peerRemoteUserId ||
                          callModalOption.idForMakingCall
                      ]?.imageUrl
                        .split("/")
                        .pop(),
                    240,
                    user.custId,
                    callModalOption.peerRemoteUserId ||
                      callModalOption.idForMakingCall,
                    spacesConfig
                  )}
                  alt="User"
                  onError={() => setImageError(true)}
                />
              ) : (
                <span className="inline-flex h-9 w-9 items-center justify-center rounded-full bg-blue">
                  <span className="text-sm font-medium leading-none text-white">
                    {getInitials(callModalOption.peerName)}
                  </span>
                </span>
              )}
              <p>
                {!isPrivateCallOn && !callModalOption.callAcceptedButton
                  ? `Calling`
                  : null}{" "}
                <span className=" text-blue font-semibold">
                  {callModalOption.peerFirstName}
                </span>
              </p>
            </div>

            <div className=" flex justify-start items-center gap-2">
              <span
                className="inline-flex lg:h-9 lg:w-9 xl:h-11 xl:w-11  items-center justify-center rounded-full bg-red-600 cursor-pointer"
                onClick={() => callCut()}
              >
                <span className="text-sm font-medium leading-none text-white">
                <PhoneXMarkIcon className="text-white w-5 font-semibold" />
                </span>
              </span>
              {callModalOption.callAcceptedButton ? (
                <span
                  className="inline-flex lg:h-9 lg:w-9 xl:h-11 xl:w-11  items-center justify-center rounded-full bg-green-500 cursor-pointer"
                  onClick={() => {
                    handleRing();
                    clearTimeout(timeout.current);
                    callAccepted();
                  }}
                >
                  <span className="text-sm font-medium leading-none text-white">
                  <PhoneIcon className="text-white w-5 font-semibold" />
                  </span>
                </span>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <div className=" flex justify-start items-center gap-2">
              {imageError ? (
                <img
                  className={`inline-block h-9 w-9 rounded-full ring-2 ring-white ${
                    peerVolume &&
                    getVolumeClasses(
                      peerVolume[
                        callModalOption.peerRemoteUserId ||
                          callModalOption.idForMakingCall
                      ]
                    )
                  }`}
                  src={
                    media &&
                    media?.remotePeers[
                      callModalOption.peerRemoteUserId ||
                        callModalOption.idForMakingCall
                    ]?.imageUrl
                  }
                  alt="User"
                />
              ) : media &&
                media?.remotePeers[
                  callModalOption.peerRemoteUserId ||
                    callModalOption.idForMakingCall
                ]?.imageUrl &&
                !imageError ? (
                <img
                  className={`inline-block h-9 w-9 rounded-full ring-2 ring-white  ${
                    peerVolume &&
                    getVolumeClasses(
                      peerVolume[
                        callModalOption.peerRemoteUserId ||
                          callModalOption.idForMakingCall
                      ]
                    )
                  }`}
                  src={generateThumbnailsUrl(
                    media &&
                      media?.remotePeers[
                        callModalOption.peerRemoteUserId ||
                          callModalOption.idForMakingCall
                      ]?.imageUrl
                        .split("/")
                        .pop(),
                    240,
                    user.custId,
                    callModalOption.peerRemoteUserId ||
                      callModalOption.idForMakingCall,
                    spacesConfig
                  )}
                  alt="User"
                  onError={() => setImageError(true)}
                />
              ) : (
                <span className="inline-flex h-9 w-9 items-center justify-center rounded-full bg-blue">
                  <span className={`h-9 w-9 p-4 bg-blue rounded-full text-white flex justify-center items-center font-semibold border-4 border-white  ring-2  ring-white shadow-xl ${peerVolume &&
                        getVolumeClasses(
                          peerVolume[
                          callModalOption.peerRemoteUserId ||
                          callModalOption.idForMakingCall
                          ]
                        )
                        }`}
                    >
                    {getInitials(callModalOption.peerName)}
                  </span>
                </span>
              )}

              <p>
                In private call with{" "}
                <span className=" text-blue font-semibold">
                  {callModalOption.peerFirstName}
                </span>
              </p>
              </div>
            <div className=" flex justify-between items-center gap-2">
              <span className="inline-flex lg:h-9 lg:w-9 xl:h-11 xl:w-11  items-center justify-center rounded-full bg-red-600 cursor-pointer">
                <span className="text-sm font-medium leading-none text-white">
                  <PhoneXMarkIcon
                    onClick={() => callCut()}
                    className="text-white w-5 font-semibold"
                  />
                </span>
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
}
