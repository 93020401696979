/* eslint-disable react-hooks/exhaustive-deps */
import {
    ChevronLeftIcon,
    EllipsisHorizontalIcon,
    PaperAirplaneIcon,
    PaperClipIcon,
    TrashIcon,
    FaceSmileIcon,
    PlusCircleIcon,
} from "@heroicons/react/24/outline";
import {
    // FaceFrownIcon,
    // HandThumbUpIcon,
    // FaceSmileIcon,
    // XMarkIcon,
    ArrowDownCircleIcon,
} from "@heroicons/react/20/solid";
// import { Listbox, Transition } from "@headlessui/react";
import styles from "./styles.module.css";
import { SetStateAction, useEffect, useLayoutEffect, useRef, useState /*Fragment*/ } from "react";
import { ChatDetailsProps, IChatAttachment } from "../../interfaces/componentsInterface/chats";
import toast from "react-hot-toast";
import {
    allowedDocumentTypes,
    allowedImageTypes,
    chatType,
    FILE_UPLOADS_CHAT,
    socketConnectiontype,
} from "../../utils/constant";
import SocketService from "../../services/socket";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import {
    chatSelector,
    seenMessage,
    softEditMessage,
    softDeleteMessage,
    replaceMessageList,
    draftMessage,
    resetChatState,
    setUnseenId,
    clearUnseenId,
} from "../../features/chat/chatSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getInitials } from "../../utils/initials";
import { getToken } from "../../utils/Token";
import axios from "axios";
import PdfIcon from "./../../assets/images/pdf-icon.svg";
import PptIcon from "./../../assets/images/powerpoint-icon.svg";
import ExcelIcon from "./../../assets/images/excel-icon.svg";
import defaultIcon from "./../../assets/images/default-icon.svg";
import txtIcon from "./../../assets/images/txt-icon.svg";
import wordIcon from "./../../assets/images/word-icon.svg";
import csvIcon from "./../../assets/images/csv-icon.svg";
import { IMessage } from "../../interfaces/slicesInterface/chat";
import { SenderActions } from "../../interfaces/componentsInterface/chats";
import moment from "moment";
import DoubleCheckIcon from "../DoubleCheckIcon";
import { roomSelector } from "../../features/room/roomSlice";
import { fetchChatListing, fetchChatOfUser } from "../../services/chat.service";
import ImageMessagePreview from "../ImageMessagePreviewProps";
import EmojiPicker from "../EmojiPicker";
import { Mention, MentionsInput, SuggestionDataItem } from "react-mentions";
import WarningModal from "../WarningModal";
import { personalizationSelector } from "../../features/personalization/personalization";
import { environmentSelector } from "../../features/environment/environmentSlice";
import { generateThumbnailsUrl } from "../../utils/generateImageURL";
import { truncateMessage } from "../../utils/truncateMessage";
import MentionInput from "../MentionInput/MentionInput";

// PaperClipIcon,

// function classNames(...classes: any[]) {
//     return classes.filter(Boolean).join(" ");
// }
const socketInstanceChat = new SocketService(socketConnectiontype.chat).getInstance(
    socketConnectiontype.chat
);

export default function ChatDetails(props: ChatDetailsProps): ReturnType<React.FC> {
    // const [selected, setSelected] = useState(moods[5]);
    const [typedMessage, setTypedMessage] = useState("");
    const loggedInUser = useAppSelector(userDetailsSelector);
    const chatDetails = useAppSelector(chatSelector);
    const roomDetails = useAppSelector(roomSelector);
    const personalization = useAppSelector(personalizationSelector);
    const environments = useAppSelector(environmentSelector);
    const spacesConfig = environments.environments && environments.environments.REACT_APP_SPACES_CONFIG ? environments.environments.REACT_APP_SPACES_CONFIG.data[0] : '';

    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [unreadCount, setUnreadCount] = useState(0);
    const [fileUrl, setFileUrl] = useState("");
    const [fileName, setFileName] = useState("");
    const [attachments, setAttachments] = useState<IChatAttachment[]>([]);
    const [imageUploading, setImageUploading] = useState(false);
    const [contentType, setContentType] = useState("text");
    const [visibleElipsisDiv, setVisibleElipsisDiv] = useState<number | null>(null);
    const [visibleElipsisReceiverDiv, setVisibleElipsisReceiverDiv] = useState<number | null>(null);
    const [imageError, setImageError] = useState(false);
    const dispatch = useAppDispatch();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [editObject, setEditObject] = useState<IMessage | object | any>({});
    const [deleteObject, setDeleteObject] = useState<IMessage | object | any>({});
    const [replyObject, setReplyObject] = useState<IMessage | object | any>({});
    const [isReply, setIsReply] = useState<boolean>(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
    const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
    const typingTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const emojiPickerRef = useRef<HTMLDivElement>(null);
    const controlsOptionRef = useRef<HTMLDivElement>(null);
    const controlsOption2Ref = useRef<HTMLDivElement>(null);
    const [isFetchChatListing, setisFetchChatListing] = useState(false)


    const fileUploadAccessForChat = environments.environments && environments.environments.FILE_UPLOADS_CHAT
        ? environments.environments.FILE_UPLOADS_CHAT.status
        : FILE_UPLOADS_CHAT;
    const handleEmojiSelect = (emoji: string) => {
        setTypedMessage((prevText) => prevText + emoji);
    };

    // const moods = [

    //     { name: 'Happy', value: 'happy', icon: FaceSmileIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400' },
    //     { name: 'Sad', value: 'sad', icon: FaceFrownIcon, iconColor: 'text-white', bgColor: 'bg-yellow-500' },
    //     { name: 'Like', value: 'like', icon: HandThumbUpIcon, iconColor: 'text-white', bgColor: 'bg-blue' },
    //     { name: 'I feel nothing', value: null, icon: XMarkIcon, iconColor: 'text-gray-400', bgColor: 'bg-transparent' },

    // ]
    const options: SenderActions[] = [
        { id: 1, name: "Edit Chat" },
        { id: 3, name: "Reply Chat" },
        { id: 2, name: "Delete Chat" },
    ];

    const options2: SenderActions[] = [
        { id: 1, name: "Reply Chat" }
    ];
    // function classNames(...classes: any[]) {
    //     return classes.filter(Boolean).join(' ')
    // }


    useEffect(() => {
        console.log({ details: props.userDetails })
    }, [props.userDetails])

    const handleBack = () => {
        props.setShowDetails(false);
        dispatch(resetChatState());
        // dispatch(fetchChatListing({
        //     loggedInUserId: loggedInUser.userId,
        //     clientId: loggedInUser.custId,
        //     roomId: roomDetails.currentRoomId,
        // }));
        // if ((typedMessage.trim().length !== 0 && props.userDetails.userId)) {
        // dispatch(draftMessage({partnerUserId:props.userDetails.userId,draft:typedMessage}));
        // }
    };

    const scrollToBottom = () => {
        // @ts-ignore
        messagesEndRef.current?.scrollIntoView({ behavior: "instant" });
    };

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const threshold = 5; // Ignore little bit differences of positions.
        const target = e.currentTarget as HTMLDivElement;
        const atBottom =
            Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) < threshold;
        setIsAtBottom(atBottom);

        if(atBottom){
            dispatch(clearUnseenId())
        }
        if (atBottom && unreadCount > 0) {
            setUnreadCount(0);
        }
    };

    useEffect(() => {
        const latestMessage = chatDetails.messageList[chatDetails.messageList.length - 1];

        const isSelfUser = latestMessage?.sender.senderUsername === loggedInUser.username ;
        if (latestMessage && !isSelfUser && !isAtBottom && latestMessage?.chatType===chatDetails?.selectedChatUser?.chatType && ((latestMessage?.chatType==="PRIVATE" && latestMessage?.sender?.senderUserId=== chatDetails?.selectedChatUser?.userId) || (latestMessage?.chatType!=="PRIVATE")) ) {
            setUnreadCount((prev) => prev + 1);
        } else if (isAtBottom || isSelfUser) {
            // Auto scroll for self user if their view is on old messages
            scrollToBottom();
            setUnreadCount(0);
        }
    }, [chatDetails.messageList.length]);

    useLayoutEffect(() => {
        async function fetchChats() {
            const chats = await fetchChatOfUser(
                loggedInUser.userId,
                chatDetails.selectedChatUser.userId,
                loggedInUser.custId,
                chatDetails.selectedChatUser.selectedRoomId,
                chatDetails.selectedChatUser.chatType
            );
            if(chatDetails.selectedChatUser?.chatType==="PRIVATE" && chatDetails.selectedChatUser.sentOrReceived==="received"){
                dispatch(setUnseenId(chats.data))
            }
            dispatch(replaceMessageList(chats.data));
            scrollToBottom();
        }

        fetchChats();
        return () => {
            dispatch(resetChatState());
            dispatch(fetchChatListing({
                loggedInUserId: loggedInUser.userId,
                clientId: loggedInUser.custId,
                roomId: roomDetails.currentRoomId,
            }));
        };
    }, []);

    const checkLuhn = (cardNo: string): boolean => {
        //used Luhn10 algo for validating and filtering credit cards.
        const nDigits = cardNo.length;

        let nSum = 0;
        let isSecond = false;
        for (let i = nDigits - 1; i >= 0; i--) {
            const d = parseInt(cardNo[i], 10);

            if (isSecond === true) {
                const doubled = d * 2;
                nSum += Math.floor(doubled / 10) + (doubled % 10);
            } else {
                nSum += d;
            }

            isSecond = !isSecond;
        }
        return nSum % 10 === 0;
    };

    const sendMessage = (input: string): void => {
        if (!isEdit) {
            if ((input && input.trim().length !== 0) || fileUrl) {
                if (input && input.trim().length !== 0) {
                    const amex = /([0-9]{4})[ -]?([0-9]{6})[ -]?([0-9]{5})[ -]?/; //credit card with spaces 4-6-5.
                    const visa = /(([0-9]{4}[ -]?([0-9]{4})[ -]?([0-9]{4})[ -]?([0-9]{4})[ -]?))/; //credit cards with spaces 4.
                    const cup2 = /(([0-9]{6})[ -]([0-9]{13})[ -]?)/; //credit card with spaces 6-13.
                    const diners1 = /(([0-9]{5}[ -]?([0-9]{4})[ -]?([0-9]{5})[ -]?))/; //credit card with spaces 5-4-5.

                    let gettingCreditCard: RegExpMatchArray | null = null;

                    if (visa.test(input)) {
                        gettingCreditCard = input.match(visa);
                    } else if (amex.test(input)) {
                        gettingCreditCard = input.match(amex);
                    } else if (diners1.test(input)) {
                        gettingCreditCard = input.match(diners1);
                    } else if (cup2.test(input)) {
                        gettingCreditCard = input.match(cup2);
                    }

                    if (
                        gettingCreditCard &&
                        gettingCreditCard.length > 0 &&
                        checkLuhn(gettingCreditCard[0].replaceAll(" ", "").replaceAll("-", ""))
                    ) {
                        toast("Can't share personal information: Can't send a message!");
                        return;
                    }
                }

                let roomData = { roomName: "", roomId: "" }
                if (chatDetails.selectedChatUser.chatType === chatType.OTHERROOM) {
                    roomData.roomName = chatDetails.selectedChatUser.selectedRoom;
                    roomData.roomId = chatDetails.selectedChatUser.selectedRoomId;
                } else {
                    roomData.roomName = roomDetails.currentRoom;
                    roomData.roomId = roomDetails.currentRoomId;
                }

                let dataTosendForStopTypeMentionCase = {
                    sender: { userId: loggedInUser.userId, socketId: '', peerName: loggedInUser.firstName + ' ' + loggedInUser.lastName },
                    chatType: chatDetails.selectedChatUser.chatType,
                    receiver: {}
                }
                if (chatDetails.selectedChatUser.chatType === chatType.OTHERROOM) {
                    dataTosendForStopTypeMentionCase.chatType = chatType.SAMEROOM
                }
                if (chatDetails.selectedChatUser.chatType === chatType.PRIVATE) {
                    dataTosendForStopTypeMentionCase.receiver = { userId: chatDetails.selectedChatUser.userId, socketId: chatDetails.selectedChatUser.peerSessionId, peerName: chatDetails.selectedChatUser.peerName }
                }
                socketInstanceChat.sendMessage("STOP_TYPING", dataTosendForStopTypeMentionCase)

                let isReplied = false
                let reply = { message: '', userId: '', parentTimestamp: '', name:'' }
                if (isReply) {
                    isReplied = true;
                    reply.message = replyObject.message;
                    if (replyObject?.file?.filename) {
                        reply.message = `${replyObject.message} 📂${replyObject?.file?.filename}`;

                    }
                    reply.userId = replyObject.sender.senderUserId;
                    reply.parentTimestamp = replyObject.createdAt;
                    reply.name=replyObject.sender.senderFullname;
                }
                let isSelfUser = false;
                if (loggedInUser.userId === chatDetails.selectedChatUser.userId) {
                    isSelfUser = true;
                }

                socketInstanceChat.sendMessage("SEND_CHAT_MESSAGE", {
                    message: input,
                    chatType: chatDetails.selectedChatUser.chatType,
                    sender: {
                        senderSocketId: "",
                        senderUsername: loggedInUser.username,
                        senderFullname: loggedInUser.firstName + " " + loggedInUser.lastName,
                        senderUserId: loggedInUser.userId,
                        senderRole: loggedInUser.role,
                    },
                    receiver: {
                        receiverSocketId: chatDetails.selectedChatUser.peerSessionId,
                        receiverUsername: chatDetails.selectedChatUser.peerUsername,
                        receiverFullname: chatDetails.selectedChatUser.peerName,
                        receiverUserId: chatDetails.selectedChatUser.userId,
                        receiverRole: chatDetails.selectedChatUser.peerType,
                    },
                    client: { clientname: loggedInUser.custCode, clientId: loggedInUser.custId },
                    room: roomData,
                    contentType: contentType,
                    file: { filename: fileName, fileurl: fileUrl },
                    isReplied,
                    reply,
                    isSelfUser,
                    bussId: chatDetails.selectedChatUser.selectedRoomBussId || null
                });

                setTypedMessage("");
                setContentType("text");
                setFileName("");
                setFileUrl("");
                setAttachments([]);
                setReplyObject({});
                setIsReply(false);
                const isdraftMessage = chatDetails.draftMessages.find(
                    (data) => data.partnerUserId === chatDetails.selectedChatUser.userId
                );
                if (isdraftMessage) {
                    dispatch(
                        draftMessage({
                            partnerUserId: chatDetails.selectedChatUser.userId,
                            draft: "",
                        })
                    );
                }
            }
        } else {
            dispatch(softEditMessage({ _id: editObject?._id, message: typedMessage }));
            editMessageSocket();
        }
    };

    const handleFileUploadInChat = async (e: React.ChangeEvent<HTMLInputElement> | null) => {
        if (attachments.length === 0) {
            // Allow Single upload at a time

            const file = e?.target.files && e.target.files[0];
            if (file) {
                // Check if the file type is not in allowed types
                if (
                    !allowedImageTypes.includes(file.type) &&
                    !allowedDocumentTypes.includes(file.type)
                ) {
                    toast("Only images and documents uploads are allowed.");
                    return;
                }

                const formData = new FormData();
                formData.append("file", file);
                formData.append("clientname", loggedInUser.custCode);
                // formData.append("roomName", roomName);

                const token = getToken();
                try {
                    setImageUploading(true);
                    const response = await axios({
                        method: "post",
                        url: `${process.env.REACT_APP_CHAT_URL}/v1/api/uploadfiletochat`,
                        data: formData,
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: token,
                        },
                    });
                    setImageUploading(false);

                    if (response.data && response.data.location) {
                        const fileURL = response.data.location;

                        setAttachments([
                            ...attachments,
                            {
                                url: fileURL,
                                type: file.type,
                                name: file.name,
                            },
                        ]);
                        setFileUrl(fileURL);
                        setFileName(file.name);

                        // Check if the file is an image
                        if (file.type.startsWith("image/")) {
                            // Image
                            setContentType("image");
                        } else {
                            if (file.type === "application/pdf") {
                                setContentType("application/pdf");
                            } else if (file.type === "application/vnd.ms-powerpoint") {
                                setContentType("application/vnd.ms-powerpoint");
                            } else if (
                                file.type === "application/vnd.ms-excel" ||
                                file.type ===
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                                setContentType("excel");
                            } else if (file.type === "text/plain") {
                                setContentType("textDoc");
                            } else if (
                                file.type === "application/msword" ||
                                file.type ===
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            ) {
                                setContentType("msword");
                            } else if (file.type === "text/csv") {
                                setContentType("csv");
                            } else {
                                setContentType("otherDoc");
                            }
                        }
                    }
                } catch (error) {
                    setImageUploading(false);
                    console.error("Error uploading file:", error);
                } finally {
                    if (e?.target) {
                        e.target.value = '';
                    }
                }
            }
        } else {
            toast("Please send selected file first");
        }
    };

    useEffect(() => {
        if (typedMessage.trim().length !== 0 && chatDetails.selectedChatUser.userId) {
            const handleBeforeUnload = (event: BeforeUnloadEvent) => {
                event.preventDefault();

                dispatch(
                    draftMessage({
                        partnerUserId: chatDetails.selectedChatUser.userId,
                        draft: typedMessage,
                    })
                );

                //   return ''; // For some browsers
            };

            window.addEventListener("beforeunload", handleBeforeUnload);

            return () => {
                window.removeEventListener("beforeunload", handleBeforeUnload);
            };
        }
    }, []);

    const handleInputBlur = () => {
        // Call your function here when input field loses focus

        if (typedMessage.trim().length !== 0 && chatDetails.selectedChatUser.userId) {
            dispatch(
                draftMessage({
                    partnerUserId: chatDetails.selectedChatUser.userId,
                    draft: typedMessage,
                })
            );
        }
    };

    // Function to handle input change
    const handleChangeInput = (
        // e: { target: { value: SetStateAction<string> } }
    ) => {
        // setTypedMessage(e.target.value);

        // Clear existing timeout and set a new one
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        if (!typingTimeoutRef.current) {
            sendTypingStatus();
        }

        typingTimeoutRef.current = setTimeout(() => {
            sendStopTypingStatus();
            typingTimeoutRef.current = null;
        }, 700);  // Adjust debounce delay as needed
    };

    const sendTypingStatus = () => {
        // setTypedMessage(e.target.value);
        let dataTosend = {
            sender: { userId: loggedInUser.userId, socketId: '', peerName: loggedInUser.firstName + ' ' + loggedInUser.lastName },
            chatType: chatDetails.selectedChatUser.chatType,
            receiver: {}
        }
        if (chatDetails.selectedChatUser.chatType === chatType.PRIVATE) {
            dataTosend.receiver = { userId: chatDetails.selectedChatUser.userId, socketId: chatDetails.selectedChatUser.peerSessionId, peerName: chatDetails.selectedChatUser.peerName }
        }
        socketInstanceChat.sendMessage("TYPING", dataTosend)
    };

    const checkEnterKey = (
        e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>
    ) => {
        if (e.key === "Enter" && !e.shiftKey) {
            // Stops enter from creating a new line
            e.preventDefault();
            if (typedMessage.trim().length !== 0) {
                if (!isEdit) {
                    sendMessage(typedMessage);
                } else {
                    dispatch(softEditMessage({ _id: editObject?._id, message: typedMessage }));
                    editMessageSocket();
                }
                setTypedMessage("");
            }
        }
    };

    const editMessageSocket = () => {
        let roomData = { roomName: "", roomId: "" }
        if (chatDetails.selectedChatUser.chatType === chatType.OTHERROOM) {
            roomData.roomName = chatDetails.selectedChatUser.selectedRoom;
            roomData.roomId = chatDetails.selectedChatUser.selectedRoomId;
        } else {
            roomData.roomName = roomDetails.currentRoom;
            roomData.roomId = roomDetails.currentRoomId;
        }
        let dataToSend = {
            _id: editObject?._id,
            edited: true,
            editedMessage: typedMessage,
            receiverSocketId: "",
            senderSocketId: "",
            chatType: chatDetails.selectedChatUser.chatType,
            room: roomData,
            bussId: chatDetails.selectedChatUser.selectedRoomBussId || null
        };
        if (chatDetails.selectedChatUser.chatType === chatType.PRIVATE) {
            dataToSend.receiverSocketId = chatDetails.selectedChatUser.peerSessionId;
        }
        socketInstanceChat.sendMessage("EDIT_MESSAGE", dataToSend);
        setIsEdit(false);
        setEditObject({});
        setTypedMessage("");
    };

    const deleteMessageSocket = (message: IMessage) => {
        dispatch(softDeleteMessage(message));

        let roomData = { roomName: "", roomId: "" }
        if (chatDetails.selectedChatUser.chatType === chatType.OTHERROOM) {
            roomData.roomName = chatDetails.selectedChatUser.selectedRoom;
            roomData.roomId = chatDetails.selectedChatUser.selectedRoomId;
        } else {
            roomData.roomName = roomDetails.currentRoom;
            roomData.roomId = roomDetails.currentRoomId;
        }

        let dataToSend = {
            _id: message?._id,
            deleted: true,
            receiverSocketId: "",
            senderSocketId: "",
            chatType: chatDetails.selectedChatUser.chatType,
            room: roomData,
            bussId: chatDetails.selectedChatUser.selectedRoomBussId || null
        };
        if (chatDetails.selectedChatUser.chatType === chatType.PRIVATE) {
            dataToSend.receiverSocketId = chatDetails.selectedChatUser.peerSessionId;
        }
        socketInstanceChat.sendMessage("DELETE_MESSAGE", dataToSend);
    };

    const truncateAttachmentName = (attachmentName: string) => {
        const maxLength = 25;
        if (attachmentName.length > maxLength) {
            return attachmentName.substring(0, maxLength) + "...";
        }
        return attachmentName;
    };

    const removeAttachment = (attachment: IChatAttachment) => {
        // TODO: Also remove file from Digital Ocean Spaces
        setAttachments(attachments.filter((a) => a.url !== attachment.url));
        setFileName("");
        setFileUrl("");
    };

    const toggleElipsisMenuVisibility = (id: number) => {
        if (visibleElipsisDiv === id) {
            setVisibleElipsisDiv(null);
        } else {
            setVisibleElipsisDiv(id);
            setVisibleElipsisReceiverDiv(null);
        }
    };

    const toggleElipsReceiverisMenuVisibility = (id: number) => {
        if (visibleElipsisReceiverDiv === id) {
            setVisibleElipsisReceiverDiv(null);
        } else {
            setVisibleElipsisReceiverDiv(id);
            setVisibleElipsisDiv(null);
        }
    };

    const messageAction = (message: IMessage, option: SenderActions) => {
        if (option.id === 2) {
            setDeleteObject(message);
            setIsWarningModalOpen(true);
            // deleteMessageSocket(message);
        } else if (option.id === 1) {
            setIsEdit(true);
            setEditObject(message);
            setTypedMessage(message.message);
        } else if (option.id === 3) {
            setReplyObject(message);
            setIsReply(true);
        }
        setVisibleElipsisDiv(null);
        // In state set Delete to true
        // Then in DB do the same for this message
    };

    const messageReceiverAction = (message: IMessage, option: SenderActions) => {
        if (option.id === 1) {
            setReplyObject(message);
            setIsReply(true);
        }
        // setDeleteObject(message);
        // setIsWarningModalOpen(true);
        // deleteMessageSocket(message);
        // } else if (option.id === 1) {
        //     setIsEdit(true);
        //     setEditObject(message);
        //     setTypedMessage(message.message);
        // }
        setVisibleElipsisReceiverDiv(null);
        // In state set Delete to true
        // Then in DB do the same for this message
    };
    const convertTimestampToTime = (timestamp: string): string => {
        return moment(timestamp).format("LT");
    };
    // console.log({unseenId:chatDetails?.unseenId})
    const markdownToHtml = (text: string): string => {
        // Function to escape HTML
        const escapeHtml = (unsafeText: string) => {
            return unsafeText
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#039;");
        };

        // Convert bold and italic text but escape the inner text
        text = text.replace(/\*\*(.+?)\*\*/g, (match, p1) => `<strong>${escapeHtml(p1)}</strong>`);
        text = text.replace(/\*(.+?)\*/g, (match, p1) => `<em>${escapeHtml(p1)}</em>`);

        // Convert links and escape the label
        text = text.replace(/\[(.+?)\]\((.+?)\)/g, (match, label, url) => {
            label = escapeHtml(label); // Escape label
            // Ensure URL is valid and doesn't start with 'javascript:'
            try {
                const sanitizedUrl = new URL(url).toString();
                if (/^javascript:/i.test(sanitizedUrl)) {
                    return label; // Return the label without making it a link
                }
                return `<a href="#" rel="noopener noreferrer">${label}</a>`;
            } catch (e) {
                return `<a href="#" rel="noopener noreferrer">${label}</a>`;
            }
        });

        // text = text.replace(/@ ?([\w\s]+?)(?=⠀|$)/g, '@ <span class="mention">$1</span>');
           text = text.replace(/@ ?([\w\s]+?)(?=‎)/g, '@ <span class="mention">$1</span>');
        return text;
    };

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                let dataToSend = {
                    senderSocketId: '',
                    senderUserId: loggedInUser.userId,
                    receiverUserId: chatDetails.selectedChatUser.userId,
                    receiverSocketId: chatDetails.selectedChatUser.peerSessionId
                }
                socketInstanceChat.sendMessage("UPDATE_SEEN_STATUS", dataToSend);
                let partnerUser = {
                    userId: loggedInUser.userId
                }
                setisFetchChatListing(true)
                dispatch(seenMessage({ partnerUser }));
            }
        };

        if (chatDetails.selectedChatUser.chatType === chatType.PRIVATE && chatDetails.messageList.length > 0) {
            handleVisibilityChange();
            document.addEventListener("visibilitychange", handleVisibilityChange);
        }
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [chatDetails.messageList.length]);
    useEffect(() => {
     if(isFetchChatListing){
        if(roomDetails && loggedInUser){
                    dispatch(fetchChatListing({
                        loggedInUserId: loggedInUser.userId,
                        clientId: loggedInUser.custId,
                        roomId: roomDetails.currentRoomId,
                    }));
                }

     }
    }, [isFetchChatListing])

    useEffect(() => {
        const draftForUser = chatDetails.draftMessages.find(
            (data) => data.partnerUserId === chatDetails.selectedChatUser.userId
        );
        if (draftForUser) {
            setTypedMessage(draftForUser.draft);
        }
    }, []);

    function extractOnlinePeerUsernames(): SuggestionDataItem[] {
        if (chatDetails.selectedChatUser.chatType === chatType.SAMEROOM) {
            return chatDetails.onlineUsers.map((peer: any) => ({
                id: peer.peerName,
                display: peer.peerName,
            }));
        } else {
            return [
                {
                    id: chatDetails.selectedChatUser.peerName,
                    display: chatDetails.selectedChatUser.peerName,
                },
            ];
        }
    }
    function extractOnlineAllPeerUsernames(): string[] {
        if (chatDetails.selectedChatUser.chatType === chatType.SAMEROOM) {
            return chatDetails.onlineUsers.map((peer: any) => (peer.peerName));
        } else {
            return [
                chatDetails.selectedChatUser.peerName
            ];
        }
    }

    const sendStopTypingStatus = () => {
        let dataTosend = {
            sender: { userId: loggedInUser.userId, socketId: '', peerName: loggedInUser.firstName + ' ' + loggedInUser.lastName },
            chatType: chatDetails.selectedChatUser.chatType,
            receiver: {}
        }
        if (chatDetails.selectedChatUser.chatType === chatType.PRIVATE) {
            dataTosend.receiver = { userId: chatDetails.selectedChatUser.userId, socketId: chatDetails.selectedChatUser.peerSessionId, peerName: chatDetails.selectedChatUser.peerName }
        }
        socketInstanceChat.sendMessage("STOP_TYPING", dataTosend)
    }
    useEffect(() => {
        console.log({ popup: props.isChatPopupOpened })
    }, [props.isChatPopupOpened])

    const handleClickOutside = (event: MouseEvent) => {
        if (
            emojiPickerRef.current &&
            !emojiPickerRef.current.contains(event.target as Node)
        ) {
            setShowEmojiPicker(false);
        }
        if (
            controlsOptionRef.current&&
            !controlsOptionRef.current.contains(event.target as Node)
        ) {
            setVisibleElipsisDiv(null)
        }
        if (
            controlsOption2Ref.current&&
            !controlsOption2Ref.current.contains(event.target as Node)
        ) {
            setVisibleElipsisReceiverDiv(null);
        }
    };
    useEffect(() => {
        const targetDocument = props.isChatPopupOpened && props.popupWindow ? props.popupWindow.document : document;
        // Attach event listener to the popup's document if in popup, otherwise main window
        targetDocument.addEventListener('mousedown', handleClickOutside);
        return () => {
          targetDocument.removeEventListener('mousedown', handleClickOutside);
        };
      }, [props.isChatPopupOpened, props.popupWindow]);

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);
    return (
        <>
            <div
                className={` ${props.isChatPopupOpened ? "h-screen" : "h-full"} w-full flex flex-col justify-between items-center relative overflow-x-hidden`}
            >
                {/* START HEADER USERNAME */}
                <div
                    className={`w-full ${props.active ? "xl:h-[25%]" : "xl:h-[11%]"
                        }  flex justify-between items-start px-3 border-[0.5px] border-transparent border-b-gray-400 pb-2`}
                >
                    <div className="flex justify-center items-start">
                        <ChevronLeftIcon
                            className="h-5 w-5 mr-2 text-gray-500 hover:text-gray-900 transition-colors cursor-pointer"
                            onClick={handleBack}
                        />
                        <div className=" flex justify-center items-center xl:gap-4 lg:gap-3 sm:gap-3  ">
                            <span className="relative inline-block">

                                {chatDetails.selectedChatUser.chatType === chatType.PRIVATE ?

                                    chatDetails.selectedChatUser.peerUsername === loggedInUser.username ? personalization.personalization && personalization.personalization?.imageUrl && imageError ? <img
                                        className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                        src={personalization.personalization?.imageUrl}
                                        alt=""

                                    /> :
                                        personalization.personalization && personalization.personalization?.imageUrl && !imageError ? <img
                                            className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                            src={generateThumbnailsUrl(personalization.personalization?.imageUrl.split('/').pop(), 240, loggedInUser.custId, loggedInUser.userId, spacesConfig)}
                                            alt=""
                                            onError={() => setImageError(true)}
                                        /> :
                                            <span className="inline-flex h-9 w-9  items-center justify-center rounded-full bg-blue">
                                                <span className="text-sm font-medium leading-none text-white">

                                                    {getInitials(
                                                        `${chatDetails.selectedChatUser.peerName}`
                                                    )}

                                                </span>
                                            </span> :



                                        chatDetails.selectedChatUser && chatDetails.selectedChatUser?.imageUrl && imageError ? <img
                                            className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                            src={chatDetails.selectedChatUser?.imageUrl}
                                            alt=""

                                        /> :
                                            chatDetails.selectedChatUser && chatDetails.selectedChatUser?.imageUrl && !imageError ? <img
                                                className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                                src={generateThumbnailsUrl(chatDetails.selectedChatUser?.imageUrl.split('/').pop(), 240, loggedInUser.custId, loggedInUser.userId, spacesConfig)}
                                                alt=""
                                                onError={() => setImageError(true)}
                                            /> :
                                                <span className="inline-flex h-9 w-9  items-center justify-center rounded-full bg-blue">
                                                    <span className="text-sm font-medium leading-none text-white">

                                                        {getInitials(
                                                            `${chatDetails.selectedChatUser.peerName}`
                                                        )}

                                                    </span>
                                                </span>



                                    : <>
                                        <span className="inline-flex h-9 w-9  items-center justify-center rounded-full bg-blue">
                                            <span className="text-sm font-medium leading-none text-white">

                                                {getInitials(
                                                    `${chatDetails.selectedChatUser.selectedRoom}`
                                                )}

                                            </span>
                                        </span>
                                    </>}

                                {/* Conditional rendering for online/offline status */}
                                {chatDetails.selectedChatUser.chatType === chatType.PRIVATE ? (
                                    <span
                                        className={`absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full ${chatDetails.onlineUsers.some(
                                            (onlineUser) =>
                                                onlineUser.userId ===
                                                chatDetails.selectedChatUser.userId
                                        )
                                            ? "bg-green-500"
                                            : "bg-gray-500"
                                            } ring-2 ring-white`}
                                    />
                                ) : null}
                            </span>
                            <div>
                                <h2 className=" text-gray-900 font-semibold lg:text-sm xl:text-base sm:text-base">
                                    {truncateMessage(chatDetails.selectedChatUser.chatType === chatType.PRIVATE
                                        ? chatDetails.selectedChatUser.peerName
                                        : chatDetails.selectedChatUser.selectedRoom,17)}
                                </h2>
                                {chatDetails.selectedChatUser.chatType === chatType.PRIVATE ? (
                                    chatDetails.onlineUsers.some(
                                        (onlineUser) =>
                                            onlineUser.userId ===
                                            chatDetails.selectedChatUser.userId
                                    ) ? (
                                        <span className=" text-green-500 lg:text-sm xl:text-base sm:text-base">
                                            online
                                        </span>
                                    ) : (
                                        <span className=" text-gray-500 lg:text-sm xl:text-base sm:text-base">
                                            offline
                                        </span>
                                    )
                                ) : null}
                            </div>
                        </div>
                    </div>
                    {/* <p className=" text-base text-gray-500 h-5 w-5">
                        <EllipsisHorizontalIcon />
                    </p> */}
                </div>
                {/* END HEADER USERNAME */}

                <div
                    onScroll={handleScroll}
                    className={`${styles.height} w-full overflow-x-hidden ${props.isChatPopupOpened ? "h-full overflow-y-scroll scrollbar" : "overflow-y-scroll"}   p-3 pt-3 pb-1 flex justify-start items-start flex-col gap-4`}
                >
                    {chatDetails.messageList.map((message, idx) => (
                        <>
                            {/* Receiver Message Case (LEFT) */}
                            {message.sender.senderUserId !== loggedInUser.userId &&
                                (
                                    (chatDetails.selectedChatUser.chatType === chatType.PRIVATE &&
                                        message.chatType === chatType.PRIVATE &&
                                        message.sender.senderUserId === chatDetails.selectedChatUser.userId) ||
                                    (chatDetails.selectedChatUser.chatType === chatType.SAMEROOM &&
                                        message.chatType === chatType.SAMEROOM) ||
                                    (chatDetails.selectedChatUser.chatType === chatType.OTHERROOM &&
                                        message.chatType === chatType.OTHERROOM && message.room.roomId === chatDetails.selectedChatUser.selectedRoomId) ||
                                    (chatDetails.selectedChatUser.chatType === chatType.SAMEROOM && message.chatType === chatType.OTHERROOM && message.room.roomId === chatDetails.selectedChatUser.selectedRoomId) ||
                                    (chatDetails.selectedChatUser.chatType === chatType.OTHERROOM && message.chatType === chatType.SAMEROOM && message.room.roomId === chatDetails.selectedChatUser.selectedRoomId)

                                ) ? (
                                <div className="flex justify-between flex-col items-start w-auto max-w-[80%]  relative xl:mt-2 ">
                                {chatDetails.selectedChatUser.chatType==="PRIVATE" && chatDetails?.unseenId === message?._id && <div ref={messagesEndRef} />}
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex justify-between items-center gap-1 ">
                                            {message.chatType !== chatType.PRIVATE ? (
                                                <span className="relative inline-block">

                                                    {/* Conditional rendering for online/offline status */}
                                                    {/** TEMPORARY */}
                                                    {message.chatType === chatType.PRIVATE ? (
                                                        <span
                                                            className={`absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full ${chatDetails.onlineUsers.some(
                                                                (onlineUser) =>
                                                                    onlineUser.userId ===
                                                                    chatDetails.selectedChatUser
                                                                        .userId
                                                            )
                                                                ? "bg-green-500"
                                                                : "bg-gray-500"
                                                                } ring-2 ring-white`}
                                                        />
                                                    ) : null}
                                                </span>
                                            ) : null}
                                            <span className="inline-flex w-9 h-9  items-center justify-center rounded-full bg-blue">
                                                <span className="text-sm font-medium leading-none text-white">
                                                    {getInitials(
                                                        `${message.sender.senderFullname}`
                                                    )}
                                                </span>
                                            </span>
                                            <p className="font-sans text-gray-900 capitalize lg:text-sm xl:text-base">
                                                {truncateMessage(message.sender.senderFullname,16)}
                                            </p>
                                        </div>
                                    </div>
                                    {!message.deleted && (
                                        <>
                                            <p
                                                className={`text-sm top-[8%] relative max-w-full text-gray-900 bg-gray-100 rounded-md p-2 pt-3 shadow-sm rounded-tl-none break-words flex flex-col ${styles.toggle}`}
                                            >
                                                {message.contentType !== "text" && (
                                                    <div className="max-w-xs cursor-pointer p-2.5 m-2.5 shadow-md">
                                                        {message.contentType === "image" ? (
                                                            <ImageMessagePreview
                                                                fileurl={message.file.fileurl}
                                                                contentType={message.contentType}
                                                            />
                                                        ) : (
                                                            (() => {
                                                                let icon;
                                                                switch (message.contentType) {
                                                                    case "application/pdf":
                                                                        icon = PdfIcon;
                                                                        break;
                                                                    case "application/vnd.ms-powerpoint":
                                                                        icon = PptIcon;
                                                                        break;
                                                                    case "excel":
                                                                        icon = ExcelIcon;
                                                                        break;
                                                                    case "textDoc":
                                                                        icon = txtIcon;
                                                                        break;
                                                                    case "msword":
                                                                        icon = wordIcon;
                                                                        break;
                                                                    case "csv":
                                                                        icon = csvIcon;
                                                                        break;
                                                                    case "otherDoc":
                                                                    default:
                                                                        icon = defaultIcon;
                                                                        break;
                                                                }

                                                                return (
                                                                    <a
                                                                        title={
                                                                            message.file.filename
                                                                        }
                                                                        href={message.file.fileurl}
                                                                        download={
                                                                            message.file.filename
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        className="flex justify-center items-center text-black bg-gray-50 gap-2 p-4 rounded-lg shadow-md cursor-pointer w-[100%] overflow-hidden"
                                                                    >
                                                                        <span className="text-3xl text-gray-800 mr-2.5 flex-shrink-0">
                                                                            <img
                                                                                src={icon}
                                                                                alt="File icon"
                                                                                className="w-8 h-8"
                                                                            />
                                                                        </span>
                                                                        <span className="text-sm truncate">
                                                                            {message.file.filename}
                                                                        </span>
                                                                    </a>
                                                                );
                                                            })()
                                                        )}
                                                    </div>
                                                )}
                                                <span
                                                    className={` text-base absolute top-0 right-1 cursor-pointer items-start`}
                                                    onClick={() =>
                                                        toggleElipsReceiverisMenuVisibility(idx)
                                                    }
                                                >
                                                    <EllipsisHorizontalIcon className="w-5" />
                                                </span>
                                                {/*Receiver Reply*/}
                                                {message?.isReplied && (
                                                    <div className="p-2 mt-1 mr-1 rounded-md mb-3 bg-blue flex">
                                                        <div className=" border-l-4 border-gray-300 pr-1" ></div>
                                                        <div className="max-w-[98%]">
                                                            <div className="flex items-center  mb-0 align-middle justify-between text-center">
                                                                <span className="text-white   text-sm pr-1 truncate max-w-[73%]">
                                                                {message?.reply?.name}
                                                                </span>
                                                                <span className="text-gray-300 text-xs">
                                                                {convertTimestampToTime(message?.reply?.parentTimestamp)}
                                                                </span>
                                                            </div>
                                                            <p className="max-w-full truncate text-white">
                                                            <span
                                                                className="text-white"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: markdownToHtml(message?.reply?.message as string),
                                                                }}
                                                            ></span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: markdownToHtml(
                                                            message.message as string
                                                        ),
                                                    }}
                                                ></span>

                                                <span className="flex justify-between">
                                                    <span className="font-sans text-xs text-start  text-gray-500 pr-1">
                                                        {convertTimestampToTime(message.createdAt)}
                                                    </span>
                                                    <span className="font-sans text-xs text-end  text-gray-500 flex gap-1 items-center">
                                                        {message?.edited && "Edited"}{" "}
                                                    </span>
                                                </span>
                                                <div
                                                key={idx}
                                                ref={visibleElipsisReceiverDiv === idx ? controlsOption2Ref : null}
                                                className={`${visibleElipsisReceiverDiv === idx
                                                    ? "block"
                                                    : "hidden"
                                                    } absolute z-10 p-1  h-auto  border border-gray-100 flex flex-col justify-start items-start shadow-md  rounded-md bg-white -right-20  top-4 transition-all  w-[6.1rem] `}
                                            >
                                                {options2.map((option) => (
                                                    <p
                                                        onClick={() => {
                                                            messageReceiverAction(message, option);
                                                        }}
                                                        key={option.id}
                                                        className="p-1 border border-transparent border-b-gray-400 w-full last-of-type:border-none text-sm hover:bg-gray-50 cursor-pointer"
                                                    >
                                                        {option.name}
                                                    </p>
                                                ))}
                                            </div>
                                            </p>
                                        </>
                                    )}
                                    {message.deleted && (
                                        <p className="italic top-6 text-sm  left-16 bg-gray-50 rounded-md p-2 shadow-sm rounded-tr-none break-words">
                                            This message has been Deleted
                                        </p>
                                    )}
                                </div>
                            ) : null}

                            {/*Sender Message Case (RIGHT)*/}
                            {message.sender.senderUserId === loggedInUser.userId &&
                                (
                                    (chatDetails.selectedChatUser.chatType === chatType.PRIVATE &&
                                        message.chatType === chatType.PRIVATE &&
                                        message.receiver?.receiverUserId === chatDetails.selectedChatUser.userId) ||
                                    (chatDetails.selectedChatUser.chatType === chatType.SAMEROOM &&
                                        message.chatType === chatType.SAMEROOM) ||
                                    (chatDetails.selectedChatUser.chatType === chatType.OTHERROOM &&
                                        message.chatType === chatType.OTHERROOM && message.room.roomId === chatDetails.selectedChatUser.selectedRoomId) ||
                                    (chatDetails.selectedChatUser.chatType === chatType.SAMEROOM && message.chatType === chatType.OTHERROOM && message.room.roomId === chatDetails.selectedChatUser.selectedRoomId) ||
                                    (chatDetails.selectedChatUser.chatType === chatType.OTHERROOM && message.chatType === chatType.SAMEROOM && message.room.roomId === chatDetails.selectedChatUser.selectedRoomId)
                                ) ? (
                                <div
                                    className={`flex justify-between items-end w-auto max-w-[80%] relative ml-auto  ${styles.message} flex-col`}
                                >
                                    <div className="flex justify-between items-start w-full"></div>

                                    {!message.deleted && (
                                        <>
                                            <p
                                                className={`max-w-full top-6 text-sm text-white  left-16 bg-blue rounded-md p-2 pt-3 pb-1 shadow-sm rounded-tr-none  break-words flex flex-col ${styles.toggle}`}
                                            >
                                                {message.contentType !== "text" && (
                                                    <div className="max-w-xs cursor-pointer p-2.5 m-2.5 shadow-md">
                                                        {message.contentType === "image" ? (
                                                            <ImageMessagePreview
                                                                fileurl={message.file.fileurl}
                                                                contentType={message.contentType}
                                                            />
                                                        ) : (
                                                            (() => {
                                                                let icon;
                                                                switch (message.contentType) {
                                                                    case "application/pdf":
                                                                        icon = PdfIcon;
                                                                        break;
                                                                    case "application/vnd.ms-powerpoint":
                                                                        icon = PptIcon;
                                                                        break;
                                                                    case "excel":
                                                                        icon = ExcelIcon;
                                                                        break;
                                                                    case "textDoc":
                                                                        icon = txtIcon;
                                                                        break;
                                                                    case "msword":
                                                                        icon = wordIcon;
                                                                        break;
                                                                    case "csv":
                                                                        icon = csvIcon;
                                                                        break;
                                                                    case "otherDoc":
                                                                    default:
                                                                        icon = defaultIcon;
                                                                        break;
                                                                }

                                                                return (
                                                                    <a
                                                                        title={
                                                                            message.file.filename
                                                                        }
                                                                        href={message.file.fileurl}
                                                                        download={
                                                                            message.file.filename
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        className="flex justify-center items-center text-black bg-gray-50 gap-2 p-4 rounded-lg shadow-md cursor-pointer w-[100%] overflow-hidden"
                                                                    >
                                                                        <span className="text-3xl text-gray-800 mr-2.5 flex-shrink-0">
                                                                            <img
                                                                                src={icon}
                                                                                alt="File icon"
                                                                                className="w-8 h-8"
                                                                            />
                                                                        </span>
                                                                        <span className="text-sm truncate">
                                                                            {message.file.filename}
                                                                        </span>
                                                                    </a>
                                                                );
                                                            })()
                                                        )}
                                                    </div>
                                                )}
                                                <span
                                                    className={` text-base absolute top-0 right-1 cursor-pointer`}
                                                    onClick={() => toggleElipsisMenuVisibility(idx)}
                                                >
                                                    <EllipsisHorizontalIcon className="w-5" />
                                                </span>


                                         {/*Sender Reply*/}
                                                {message?.isReplied && (
                                                    <div className="p-2 mt-1 mr-1 rounded-md mb-3 bg-gray-100 flex">
                                                        <div className=" border-l-4 border-blue pr-1" ></div>
                                                        <div className="max-w-[98%]">
                                                            <div className="flex items-center  mb-0 align-middle justify-between text-center">
                                                                <span className="text-gray-900  text-sm pr-1 truncate max-w-[73%]">
                                                                    {message?.reply?.name}
                                                                </span>
                                                                <span className="text-gray-500 text-xs">
                                                                    {convertTimestampToTime(message?.reply?.parentTimestamp)}
                                                                </span>
                                                            </div>
                                                            <p className="max-w-full truncate text-gray-700">
                                                            <span
                                                                className="text-gray-700"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: markdownToHtml(message?.reply?.message as string),
                                                                }}
                                                            ></span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}

                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: markdownToHtml(
                                                            message.message as string
                                                        ),
                                                    }}
                                                ></span>

                                                <span className="flex justify-between">
                                                    <span className="font-sans text-xs text-start  text-gray-300 pr-1">
                                                        {convertTimestampToTime(message.createdAt)}
                                                    </span>
                                                    <span className="font-sans text-xs text-end  text-gray-300 flex gap-1 items-center">
                                                        {message?.edited && "Edited"}{" "}
                                                        <DoubleCheckIcon seen={message.seen} />
                                                    </span>
                                                </span>
                                            </p>

                                            <div
                                                key={idx}
                                                ref={visibleElipsisDiv === idx ? controlsOptionRef: null}
                                                className={`${visibleElipsisDiv === idx ? "block" : "hidden"
                                                    } absolute z-10 p-1 h-auto  border border-gray-100 flex flex-col justify-start items-start shadow-md  rounded-md bg-white right-0 top-4 transition-all  w-[6.2rem] `}
                                            >
                                                {options.map((option) => (
                                                    <p
                                                        onClick={() => {
                                                            messageAction(message, option);
                                                        }}
                                                        key={option.id}
                                                        className={`${option.id === 2 ? "text-red-600" : ""
                                                            } p-1 border border-transparent border-b-gray-400 w-full last-of-type:border-none text-sm hover:bg-gray-50 cursor-pointer`}
                                                    >
                                                        {option.name}
                                                    </p>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                    {message.deleted && (
                                        <p className="italic top-6 text-sm  left-16 bg-gray-50 rounded-md p-2 shadow-sm rounded-tr-none break-words">
                                            This message has been Deleted
                                        </p>
                                    )}
                                </div>
                            ) : null}
                        </>
                    ))}


                    {/** Below ref is for unread scroll **/}
                    {((chatDetails.selectedChatUser.chatType!=="PRIVATE" ) || (chatDetails.selectedChatUser.chatType==="PRIVATE" && chatDetails.unseenId ==="")) && <div ref={messagesEndRef} />}
                </div>
                {/* /// typing */}
                {

                    (chatDetails.typeStatus.active && chatDetails.typeStatus.chatType === chatType.PRIVATE && chatDetails.typeStatus.typerUserId === chatDetails.selectedChatUser.userId && chatDetails.typeStatus.typerUserId !== loggedInUser.userId) &&

                    (<div className="flex justify-end items-end w-[100%] h-5  absolute  bottom-10 left-2  flex-col">

                        <div className={`${styles.typing} bg-transparent `}>
                            <span className={`${styles.circle} ${styles.scaling}`}></span>
                            <span className={`${styles.circle} ${styles.scaling}`}></span>
                            <span className={`${styles.circle} ${styles.scaling}`}></span>
                        </div>
                    </div>)

                }



                {isEdit && (
                    <div className="w-full z-10 border-gray-300 border-t-2 border-b-2 absolute bottom-[11%]  right-5 bg-white">
                        <div className="border-l-4 border-blue ml-8 mr-5 my-2 text-sm  pl-1">
                            <div className="flex justify-between">
                                <p className=" text-blue ">Edit message</p>
                                <div
                                    className=" cursor-pointer"
                                    onClick={() => {
                                        setEditObject({});
                                        setIsEdit(false);
                                        setTypedMessage("");
                                    }}
                                >
                                    x
                                </div>
                            </div>
                            <p className="w-[99%] truncate">{editObject.message}</p>
                        </div>
                    </div>
                )}

                {isReply && (
                    <div className="w-full z-10 border-gray-300 border-t-2 border-b-2 absolute bottom-[11%]  right-5 bg-white">
                        <div className="border-l-4 border-blue ml-8 mr-2 my-2 text-sm  pl-1">
                            <div className="flex justify-between items-center">
                                <p className=" text-blue ">Reply message</p>
                                <div
                                    className=" cursor-pointer"
                                    onClick={() => {
                                        setReplyObject({});
                                        setIsReply(false);
                                        setTypedMessage("");
                                    }}
                                >
                                    <svg className="w-[0.6rem] h-[0.6rem]" aria-hidden="true" viewBox="0 0 14 14">
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <p className="w-[99%] truncate">
                                {replyObject.message}
                                {replyObject?.file?.filename && ` 📂${replyObject?.file?.filename}`}
                            </p>
                        </div>
                    </div>
                )}

                {/* {unreadCount > 0 && (
                    <button
                        onClick={() => {
                            scrollToBottom();
                            setUnreadCount(0);
                        }}
                        type="button"
                        className="inline-flex items-center gap-x-1.5 rounded-2xl bg-blue px-1.5 py-1 absolute bottom-[10%] text-sm font-semibold text-white shadow-sm hover:bg-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                    >
                        <ArrowDownCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        {unreadCount} Latest Message
                    </button>
                )} */}

                {imageUploading && (
                    <div className="flex items-center justify-center font-bold">
                        <i className="fa-spinner text-2xl mr-2.5"></i>
                        <strong> Uploading File...</strong>
                    </div>
                )}

                {attachments.map((attachment) => (
                    <div key={attachment.url} className="flex items-center">
                        {attachment.type.startsWith("image/") ? (
                            <img
                                src={attachment.url}
                                alt={attachment.name}
                                className="w-20 h-auto border border-gray-300 shadow-md transition-transform transform-gpu hover:scale-110 cursor-pointer"
                            />
                        ) : (
                            <>
                                <span className="text-3xl">
                                    {attachment.type === "application/pdf" ? (
                                        <img src={PdfIcon} alt="PDF icon" className="w-30" />
                                    ) : attachment.type === "application/vnd.ms-powerpoint" ? (
                                        <img src={PptIcon} alt="PowerPoint icon" className="w-30" />
                                    ) : attachment.type === "application/vnd.ms-excel" ||
                                        attachment.type ===
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                                        <img src={ExcelIcon} alt="Excel icon" className="w-30" />
                                    ) : attachment.type === "text/plain" ? (
                                        <img src={txtIcon} alt="txt icon" className="w-30" />
                                    ) : attachment.type === "application/msword" ||
                                        attachment.type ===
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                        <img src={wordIcon} alt="txt icon" className="w-30" />
                                    ) : attachment.type === "text/csv" ? (
                                        <img src={csvIcon} alt="txt icon" className="w-30" />
                                    ) : (
                                        <img src={defaultIcon} alt="txt icon" className="w-30" />
                                    )}
                                </span>{" "}
                                <span className="max-w-250 text-blue-500">
                                    {truncateAttachmentName(attachment.name)}
                                </span>
                            </>
                        )}
                        <button
                            onClick={() => removeAttachment(attachment)}
                            aria-label="Remove attachment"
                            className="bg-transparent border-none cursor-pointer px-5 text-red-500 text-3xl"
                        >
                            <TrashIcon className="h-9 w-10 p-2 py-1 bg-blue text-white rounded-md cursor-pointer" />
                        </button>
                    </div>
                ))}

                <div
                    className={` w-full ${props.active && !props.isChatPopupOpened ? "h-[40%]" : "h-[15%]"
                        }   flex justify-between items-center gap-2 p-2 pb-0 relative mb-1`}
                >
                    {unreadCount > 0 && (
                        <button
                            onClick={() => {
                                scrollToBottom();
                                setUnreadCount(0);
                            }}
                            type="button"
                            className="inline-flex items-center gap-x-1.5 rounded-2xl bg-blue px-1.5 py-1 absolute -top-3 left-1/4 text-sm font-semibold text-white shadow-sm hover:bg-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        >
                            <ArrowDownCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                            {unreadCount} {unreadCount === 1 ? 'New Message' : 'New Messages'}
                        </button>
                    )}
                    <div className="flex gap-1">
                        {
                            fileUploadAccessForChat &&
                            <label  htmlFor="fileInput" className="attach-btn w-7 h-7 cursor-pointer ">
                                <PlusCircleIcon className="text-gray-500 w-7  h-7 font-normal" title={`Upload File :: Allowed Image Types:
                        - JPEG (.jpg, .jpeg)
                        - PNG (.png)
                        - GIF (.gif)
                        - SVG (.svg)

                        Allowed Document Types:
                        - PDF (.pdf)
                        - Word (.doc, .docx)
                        - Excel (.xls, .xlsx)
                        - PowerPoint (.ppt, .pptx)
                        - Text (.txt)
                        - CSV (.csv)
                        - Rich Text Format (.rtf)
                        - JSON (.json)
                        `
                                }
                                />
                            </label>
                        }
                        <div
                            ref={emojiPickerRef}
                            className="w-7 h-7 focus:outline-none cursor-pointer"
                            onClick={() => setShowEmojiPicker((prev) => !prev)}
                        >
                            <FaceSmileIcon className="text-gray-500 w-7  h-7 font-normal" title="Emoji" />
                        </div>
                    </div>

                    {/* <MentionsInput
                        value={typedMessage}
                        onChange={handleChangeInput}
                        onKeyDown={checkEnterKey}
                        // onKeyUp={handleKeyUp}
                        onBlur={handleInputBlur}
                        placeholder="Write..."
                        className={`${!props.isChatPopupOpened && 'w-full'} border-2 mt-3 max-w-[60%] ${props.isChatPopupOpened && 'max-w-[80%] w-[75%]'} max-h-full border-gray-300 focus:border-blue  mb-3 focus:outline-none rounded-md leading-5 transition ease-in-out  sm:text-sm sm:leading-5 `}
                    >
                        <Mention
                            trigger="@"
                            data={extractOnlinePeerUsernames()}
                            markup="@<span class='mention'> __display__</span>"
                            renderSuggestion={(
                                suggestion,
                                search,
                                highlightedDisplay,
                                index,
                                focused
                            ) => (
                                <div className={focused ? "focused truncate" : "truncate"}>{highlightedDisplay}</div>
                            )}
                        />
                    </MentionsInput> */}

                    <MentionInput
                        value={typedMessage}
                        typingStatusInput={handleChangeInput}
                        onChange={setTypedMessage}
                        suggestions={extractOnlineAllPeerUsernames()}
                        checkEnterKey={checkEnterKey}
                        handleInputBlur={handleInputBlur}
                    />

                    {/* <input
                    placeholder="Type a message"
                    className={`w-full py-1.5 px-3 border-2  border-gray-300 focus:border-blue  focus:outline-none rounded-md leading-5 transition ease-in-out  sm:text-sm sm:leading-5 `}
                    onChange={handleChangeInput}
                    value={typedMessage}
                    onKeyDown={checkEnterKey}
                    onBlur={handleInputBlur}
                /> */}

                    <div ref={emojiPickerRef}
                    >
                        {showEmojiPicker && <EmojiPicker onEmojiSelect={handleEmojiSelect} isChatPopupOpened={props.isChatPopupOpened} />}
                    </div>                    {
                        fileUploadAccessForChat &&
                        <input
                            type="file"
                            onChange={handleFileUploadInChat}
                            style={{ display: "none" }}
                            id="fileInput"
                        />
                    }
                    {/* <label htmlFor="fileInput" className="attach-btn h-9 w-10 p-2 py-1 bg-blue text-white rounded-xl cursor-pointer text-center items-center flex justify-center ">
                    <PaperClipIcon />
                </label> */}

                    <PaperAirplaneIcon
                        className="h-8 w-8 p-2 py-1 bg-blue text-white rounded-xl cursor-pointer"
                        onClick={() => {
                            sendMessage(typedMessage);
                        }}
                    />
                </div>

                {isWarningModalOpen && (
                    <WarningModal
                        title="Delete message from chat"
                        message="Are you sure you want to delete this message? This action cannot be undone."
                        onConfirm={() => {
                            setIsWarningModalOpen(false);
                            deleteMessageSocket(deleteObject);
                        }}
                        onCancel={() => {
                            setIsWarningModalOpen(false);
                            setDeleteObject({});
                        }}
                    />
                )}
            </div>
        </>
    );
}
